// MATERIAL
@use 'sass:map';
@use 'node_modules/@angular/material/index' as mat;

@mixin theme($theme) {
  $color-config: mat.m2-get-color-config($theme);

  @if $color-config != null {

    // Get the primary color palette from the color-config.
    $accent-palette: map.get($color-config, 'accent');

    .light-theme {
      #dialog {
        background: mat.m2-get-color-from-palette($accent-palette, A100);
      }
    }
    .colored-theme {
      #dialog {
        background: mat.m2-get-color-from-palette($accent-palette, 500);
      }
    }

  }
}
