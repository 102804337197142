// MATERIAL
@use 'sass:map';
@use 'node_modules/@angular/material/index' as mat;

@mixin theme($theme) {
  .light-theme {
    .themed-background {
      background: var(--accent-palette-200);
    }
  }

  .colored-theme {
    .themed-background {
      background: var(--accent-palette-300);
    }
  }
}
