// MATERIAL
@use 'sass:map';
@use 'node_modules/@angular/material/index' as mat;

@import "styles/mixins";

@mixin theme($theme) {
  .light-theme {
    .qr-container {
      background: var(--accent-palette-contrast-50);
    }
  }

  .colored-theme {
    .qr-container {
      background: var(--accent-palette-300);
    }
  }
}
