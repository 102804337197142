// MATERIAL
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use "@angular/material" as mat;
@use "sass:map";

// Custom component theming
@use 'src/app/shared/components/circle-block/circle-block-theme.component' as circle-block;
@use 'src/app/shared/components/footer/footer-theme.component' as footer;
@use 'src/app/shared/components/dialog/dialog-theme.component' as dialog;
@use 'src/app/shared/components/vehicule-info/vehicle-info-theme' as vehicle-info;
@use 'src/app/features/products/pro/home/home-theme.component' as home;
@use 'src/app/features/products/pro/home/component/card-task/card-task-theme.component' as card-task;
@use 'src/app/shared/components/initial/initial-theme.component' as inital;
@use 'src/app/features/products/pro/authentication/authentication-theme.component' as authentication;
@use 'src/app/features/products/pro/profile/components/mileage/mileage-theme.component' as mileage;
@use 'src/app/shared/components/share-button/share-button-theme.component' as share;
@use 'src/app/features/shared/license/license-theme.component' as license;
@use 'src/app/shared/components/tab/tab-theme.component' as tab;
@use 'src/app/features/products/scanner/ia-results/components/single-recommendation-result/single-recommendation-result-theme' as recommendation;
@use 'src/app/features/products/scanner/ia-results/components/waiting/waiting-theme' as waiting;
@use 'src/app/features/shared/photoshoot/production/components/tyre-camera/tyre-camera.component-theme' as tyre-camera;
@use 'src/app/features/products/appointment/components/time-slots/components/garage-appointment-card/garage-appointment-card-theme.component' as garage-appointment-card;
@use 'src/app/features/products/appointment/components/info/info-theme.component' as info;
@use 'src/app/features/products/appointment/components/contact/contact-theme.component' as contact;
@use 'src/app/shared/components/dropdown/dropdown-theme.component.scss' as dropdown;
@use 'src/app/shared/components/accordion/accordion-theme.component.scss' as accordion;
@use 'src/app/features/not-supported/not-supported-theme.component.scss' as not-supported;

@use "theme";
@use "styles/colors";
@use "styles/container";
@use "styles/fonts";
@use "styles/layouts";

// Include the common themes for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
$custom-typography: mat.m2-define-legacy-typography-config(
  $font-family: "'MyFont', sans-serif",
  $body-1: mat.m2-define-typography-level(1rem, 20.8px, 400),
  $headline: mat.m2-define-typography-level(2rem, 32px, 400),
  $title: mat.m2-define-typography-level(1.5rem, 27.6px, 400),
  $subheading-2: mat.m2-define-typography-level(1rem, 20.8px, 700),
);

@include mat.all-component-typographies($custom-typography);
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$web-app-primary: mat.m2-define-palette(theme.$primary-palette);
$web-app-accent: mat.m2-define-palette(theme.$accent-palette);

// The warn palette is optional (defaults to red).
$web-app-warn: mat.m2-define-palette(mat.$m2-red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$web-app-theme: mat.m2-define-light-theme((
  color: (
    primary: $web-app-primary,
    accent: $web-app-accent,
    warn: $web-app-warn,
  )
)
);

// Include theme themes for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($web-app-theme);

// Apply Material Theme to custom components
@include home.theme($web-app-theme);
@include circle-block.theme($web-app-theme);
@include dialog.theme($web-app-theme);
@include footer.theme($web-app-theme);
@include card-task.theme($web-app-theme);
@include inital.theme($web-app-theme);
@include authentication.theme($web-app-theme);
@include mileage.theme($web-app-theme);
@include share.theme($web-app-theme);
@include license.theme($web-app-theme);
@include tab.theme($web-app-theme);
@include recommendation.theme($web-app-theme);
@include vehicle-info.theme($web-app-theme);
@include waiting.theme($web-app-theme);
@include tyre-camera.theme($web-app-theme);
@include garage-appointment-card.theme($web-app-theme);
@include info.theme($web-app-theme);
@include dropdown.theme($web-app-theme);
@include contact.theme($web-app-theme);
@include accordion.theme($web-app-theme);
@include not-supported.theme($web-app-theme);

// Tailwind
@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  font-family: 'MyFont', sans-serif;
}

html {
  min-height: 100%;
}

body {
  min-height: auto;
  flex: 1;
}

html,
body {
  display: flex;
  flex-direction: column;
  color: mat.m2-get-contrast-color-from-palette(theme.$accent-palette, 500);

  p, mat-checkbox {
    color: mat.m2-get-contrast-color-from-palette(theme.$accent-palette, 500);
  }

  hr {
    border-color: map.get(theme.$primary-palette, 500);
  }
}

app-root {
  display: flex;
  flex-direction: column;
  flex: 1;
}

:root {
  --mat-select-placeholder-text-color: rgba(mat.m2-get-contrast-color-from-palette(theme.$accent-palette, 500), 0.75);
}

h1 {
  @include mat.m2-typography-level($custom-typography, 'headline');
}

h2 {
  @include mat.m2-typography-level($custom-typography, 'title');
}

h3, label:not(.mat-mdc-checkbox label):not(.cds-checkbox-container .cds-checkbox-label, .file-input-button) {
  @include mat.m2-typography-level($custom-typography, 'subheading-2');
  font-size: 18px;
}

h4 {
  margin-bottom: 0;
}

/* Change the white to any color */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  font-family: 'MyFont', sans-serif;
}

legend {
  font-size: 1.430em !important;
  margin-bottom: 0.5rem !important;
  font-weight: lighter !important;
}

label:not(.mat-mdc-checkbox label):not(.mat-calendar-hidden-label):not(.cds-checkbox-container .cds-checkbox-label, .file-input-button) {
  margin-bottom: 0.5em;
  display: block;
}

strong {
  font-weight: 700;
}

/// Override material style ///

mat-checkbox {
  font-size: 1rem;
}

.mat-mdc-checkbox {
  white-space: normal !important;
  margin-top: 4px;

  .mdc-checkbox {
    flex-basis: 8px !important;
    margin-top: 1px !important;
    margin-right: 1px !important;

    .mat-mdc-checkbox-touch-target {
      height: 40px;
      width: 40px;
    }
  }
}

.mat-mdc-unelevated-button:not(:disabled) {
  color: var(--accent-palette-contrast-500) !important;
}

.mat-datepicker-toggle-default-icon {
  color: mat.m2-get-contrast-color-from-palette(theme.$accent-palette, 500);
}

.mat-form-field-appearance-outline {
  border-radius: 8px;
  top: 0 !important;
  height: 48px;
}

.mat-mdc-form-field {
  width: 100%;
  height: 48px !important;
}

.mat-mdc-form-field-flex {
  height: 48px;
  align-items: center !important;
  padding-left: 8px !important;
}

.mat-mdc-select-arrow-wrapper {
  transform: none !important;
}

.mat-mdc-form-field-infix {
  width: auto !important;
  height: 48px !important;
  border: 0 !important;
  padding: 0 !important;
  display: flex !important;
  align-items: center;
  top: 0 !important;
}

.mat-mdc-form-field-error {
  font-size: 12px;
}

.mat-mdc-dialog-container {
  background: map.get(theme.$accent-palette, 500);

  .mat-mdc-dialog-title {
    color: mat.m2-get-contrast-color-from-palette(theme.$accent-palette, 500)
  }
}

.mat-mdc-snack-bar-action {
  color: map.get(theme.$primary-palette, 500)
}

/// END OF CHECK

.mat-label {
  color: mat.m2-get-contrast-color-from-palette(theme.$accent-palette, 500);
}

.mat-mdc-input-element::placeholder {
  color: mat.m2-get-contrast-color-from-palette(theme.$accent-palette, 500);
}

input.mat-mdc-input-element {
  color: mat.m2-get-contrast-color-from-palette(theme.$accent-palette, 500) !important;
  caret-color: mat.m2-get-color-from-palette(theme.$primary-palette, 500);
  height: 14px !important;
  margin: 0;
  border-width: 0;
  font-size: 0.75em !important;
  line-height: 14px !important;
  z-index: 10;

  &.ng-invalid{
    caret-color: #f44336;
  }
}

.mat-datepicker-toggle {
  color: mat.m2-get-contrast-color-from-palette(theme.$accent-palette, 500);
}

div.mat-mdc-autocomplete-panel {
  background-color: white !important;
  color: rgba(0, 0, 0, 0.87);
}

mat-option:hover {
  background-color: rgba(0,0,0,.04);
}

.mat-mdc-select-value {
  color: mat.m2-get-contrast-color-from-palette(theme.$accent-palette, 500);
}

.mat-mdc-select-value {
  font-size: 0.75em;
}

.mat-mdc-select-arrow {
  color: mat.m2-get-contrast-color-from-palette(theme.$accent-palette, 500) !important;
}

::placeholder {
  color: mat.m2-get-contrast-color-from-palette(theme.$accent-palette, 200) !important;
  opacity: 1; // Firefox specific
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: mat.m2-get-contrast-color-from-palette(theme.$accent-palette, 200) !important;
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: mat.m2-get-contrast-color-from-palette(theme.$accent-palette, 200) !important;
}

//// END OF TODO CHECK PALETTE 400

/// Override phone input ///

.iti {
  display: block !important;
  font-size: 0.75em;
}

.iti .dropdown-menu.country-dropdown {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-color: #c7cace;
  margin-top: -1px;
}

.iti .iti__country-list {
  box-shadow: none;
  font-size: 14px;
  margin-left: 0;
  width: 244px;
  max-height: 170px;
}

.iti__flag-container.open + input {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.iti .search-container input {
  font-size: 14px;
  border-color: #c7cace;
  border-radius: 0;
  padding: 5px 10px;
}

.iti .search-container input:focus {
  outline: none;
}

@media screen and (max-width: 479px) {
  .iti .iti__country-list {
    width: 88.3vw;
  }
}

#country-search-box {
  color: mat.m2-get-contrast-color-from-palette(theme.$accent-palette, 400);
}

.dropdown-toggle {
  padding-right: 16px;
  width: auto !important;
}

.tel-input {
  .tel-error {
    font-size: 75%;
    line-height: 1.125;
    text-align: start;
    padding: 0 1em;
    margin-top: 0.66em;
    color: map.get(mat.$m2-red-palette, 500);
  }
}

.link-button {
  background: transparent !important;
  border: none;
  font-weight: 700;
  width: auto;
  height: auto;
  color: mat.m2-get-contrast-color-from-palette(theme.$accent-palette, 500);

  &:not(.text-base) {
    font-size: 14px;
  }
}

// Amplify theme

[data-amplify-authenticator] {
  --amplify-components-authenticator-router-background-color: transparent;
  --amplify-components-fieldcontrol-color: mat.m2-get-contrast-color-from-palette(theme.$accent-palette, 500);
  --amplify-components-heading-color: mat.m2-get-contrast-color-from-palette(theme.$accent-palette, 500);
  --amplify-components-authenticator-router-border-width: 0;
  --amplify-components-authenticator-router-box-shadow: 0;
}

.amplify-input {
  border-width: 0 !important;
  height: 48px !important;
}

.amplify-button {
  background-color: mat.m2-get-color-from-palette(theme.$accent-palette, 500);
}

.amplify-button--primary {
  background-color: map.get(theme.$primary-palette, 500) !important;
  font-weight: 700 !important;
  font-size: 1.5rem !important;
  height: 56px !important;
}

.amplify-button--link {
  color: mat.m2-get-contrast-color-from-palette(theme.$accent-palette, 500) !important;
  font-weight: 400 !important;
  font-size: 1rem !important;
}

.amplify-field-group {

  .amplify-input {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
  }

  .amplify-button {
    color: mat.m2-get-contrast-color-from-palette(theme.$accent-palette, 400);
    background-color: mat.m2-get-color-from-palette(theme.$accent-palette, 400);
    border-width: 0 !important;
  }
}

/// Custom classes ///

.primary-border {
  border-color: map.get(theme.$primary-palette, 500);
}

.primary-color {
  color: map.get(theme.$primary-palette, 500);
}

.primary-contrast-color {
  color: mat.m2-get-contrast-color-from-palette(theme.$primary-palette, 500);
}

.primary-background {
  background: map.get(theme.$primary-palette, 500);
}

.accent-border {
  border-color: map.get(theme.$accent-palette, 500);
}

.accent-contrast-border {
  border-color: mat.m2-get-contrast-color-from-palette(theme.$accent-palette, 500);
}

.accent-background-100 {
  background: map.get(theme.$accent-palette, 100);
}

.accent-background-300 {
  background: map.get(theme.$accent-palette, 300);
}

.accent-background {
  background: map.get(theme.$accent-palette, 500);
}

.accent-background-700 {
  background: map.get(theme.$accent-palette, 700);
}

.accent-contrast-background {
  background: mat.m2-get-contrast-color-from-palette(theme.$accent-palette, 500);
}

.accent-background-300 {
  background: mat.m2-get-color-from-palette(theme.$accent-palette, 300);
}

.accent-background-400, .slider:before {
  background: mat.m2-get-color-from-palette(theme.$accent-palette, 400);
}

.accent-color {
  color: map.get(theme.$accent-palette, 500);
}

.accent-contrast-color {
  color: mat.m2-get-contrast-color-from-palette(theme.$accent-palette, 500);
}

.accent-color-700 {
  color: map.get(theme.$accent-palette, 700);
}

legend, h3 {
  color: mat.m2-get-contrast-color-from-palette(theme.$accent-palette, 500);
}

.text-important {
  color: mat.m2-get-contrast-color-from-palette(theme.$accent-palette, 500) !important;
  font-weight: bold;
}

.summary-info {
  border-bottom: solid 1px rgba(mat.m2-get-contrast-color-from-palette(theme.$accent-palette, 500), 0.25);
  font-size: 1em;
  padding-bottom: 0.5em;
  padding-top: 0.5em;
}

.fill-accent {
  fill: mat.m2-get-color-from-palette(theme.$accent-palette, 500) !important;
}

.fill-accent-700 {
  fill: mat.m2-get-color-from-palette(theme.$accent-palette, 700) !important;
}

.fill-primary {
  fill: mat.m2-get-color-from-palette(theme.$primary-palette, 500) !important;
}

.fill-primary-contrast {
  fill: mat.m2-get-contrast-color-from-palette(theme.$primary-palette, 500) !important;
}

.fill-accent-contrast {
  fill: mat.m2-get-contrast-color-from-palette(theme.$accent-palette, 500) !important;
}

.stroke-accent {
  stroke: mat.m2-get-color-from-palette(theme.$accent-palette, 500) !important;
}


.stroke-accent-contrast {
  stroke: mat.m2-get-contrast-color-from-palette(theme.$accent-palette, 500) !important;
}

.stroke-primary {
  stroke: mat.m2-get-color-from-palette(theme.$primary-palette, 500) !important;
}

.stroke-primary-contrast {
  stroke: mat.m2-get-contrast-color-from-palette(theme.$primary-palette, 500) !important;
}

button.small {
  font-size: 1rem !important;
  font-weight: 700;
  height: 40px !important;
}

button.big {
  font-weight: 700;
  font-size: 1.5rem !important;
  min-height: 56px;
}

button.popup {
  font-weight: 700;
  font-size: 0.75em;
  line-height: 15.6px;
  height: 36px;
}

button {
  -webkit-tap-highlight-color: transparent;
  outline: none;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}

.mat-mdc-button.mat-mdc-button-base {
  background-color: mat.m2-get-color-from-palette(theme.$primary-palette, 500);
  height: 48px;
  border-radius: 8px;
}

.rotate {

  transform: rotate(-90deg);
  transform-origin: left top;
  width: 100vh;
  height: 100vw;
  overflow-x: hidden;
  position: absolute;
  top: 100%;
  left: 0;

  video {
    transform: rotate(90deg);
  }
}

.list{
  p:not(.mb-6):before{
    background: map.get(theme.$primary-palette, 500);
  }
}

.slider{
  &{
    background: mat.m2-get-color-from-palette(theme.$accent-palette, 100);
  }
}
input:checked + .slider {
  background: map.get(theme.$primary-palette, 500);
}

app-tcu-dialog cds-svg-icon > span{
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.light-theme {

  /////////////////////////////////////
  /////////// LIGHT THEME /////////////
  /////////////////////////////////////

  html, body {
    background: white;
  }

  /* Change the white to any color */
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-text-fill-color: mat.m2-get-contrast-color-from-palette(theme.$accent-palette, 500);
    -webkit-box-shadow: 0 0 0 8px mat.m2-get-color-from-palette(theme.$accent-palette, 100) inset !important;
  }

  .mat-mdc-unelevated-button {
    border-radius: 8px;

    &.mat-accent {
      border: 1px solid mat.m2-get-contrast-color-from-palette(theme.$accent-palette, 500) !important;
      color: mat.m2-get-contrast-color-from-palette(theme.$accent-palette, 500) !important;
      background: transparent;
    }
  }

  .mat-mdc-button.mat-mdc-button-base {
    color: mat.m2-get-contrast-color-from-palette(theme.$primary-palette, 500) !important;
  }

  .mdc-button[disabled] {
    opacity: 0.7;
    background-color: mat.m2-get-color-from-palette(theme.$primary-palette, 500) !important;
    color: mat.m2-get-contrast-color-from-palette(theme.$primary-palette, 500) !important;
  }

  .mat-form-field-appearance-outline {
    color: transparent;
    background: mat.m2-get-color-from-palette(theme.$accent-palette, 100);
  }

  .mat-mdc-form-field-outline-start,
  .mat-mdc-form-field-outline-gap,
  .mat-mdc-form-field-outline-end {
    color: mat.m2-get-color-from-palette(theme.$accent-palette, 100);
  }

  .mat-mdc-checkbox .mdc-checkbox__background {
    border-color: mat.m2-get-color-from-palette(theme.$primary-palette, 500) !important;
    width: 16px;
    height: 16px;
  }

  .mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background {
    background-color: mat.m2-get-color-from-palette(theme.$primary-palette, 500) !important;
  }

  .mdc-checkbox__checkmark-path {
    stroke: mat.m2-get-contrast-color-from-palette(theme.$primary-palette, 500) !important;
  }

  .themed-container {
    padding: 24px 16px !important;
    border-radius: 8px;
  }

  [data-amplify-form] {
    background: mat.m2-get-color-from-palette(theme.$accent-palette, 500);
    border-radius: 8px;
    padding: 24px 16px !important;
  }

  .amplify-button:not(.amplify-field__show-password) {
    border-radius: 26px;
    color: mat.m2-get-contrast-color-from-palette(theme.$primary-palette, 500);
  }

  .amplify-input {
    background-color: mat.m2-get-color-from-palette(theme.$accent-palette, 100) !important;
  }

  .amplify-button--link {
    background: mat.m2-get-color-from-palette(theme.$accent-palette, A100);
  }

  .background-cards {
    background: mat.m2-get-color-from-palette(theme.$accent-palette, 100);
  }

  .dropdown-toggle {
    background-color: mat.m2-get-color-from-palette(theme.$accent-palette, 50);
  }

  cr-loader {
    p {
      color: mat.m2-get-contrast-color-from-palette(theme.$accent-palette, 500);
    }
    svg {
      stroke: mat.m2-get-color-from-palette(theme.$primary-palette, 500) !important;
    }
  }

  .no-t-radius {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .dialog-cookies {
    li {
      &:before {
        background-color: map.get(theme.$primary-palette, 500);
      }
    }
    .color {

    }
    .background {
      background: mat.m2-get-color-from-palette(theme.$accent-palette, 400);
    }
  }

  .fill-accent-bulb {
    fill: map.get(theme.$primary-palette, 500);
  }

  .shadow {
    box-shadow: inset 0 -110px 60px -25px rgba(255,255,255, 0.8);
    position: absolute;
    pointer-events: none;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
  }

  .scrollbar {
    &::-webkit-scrollbar-thumb {
      background: var(--accent-palette-300);
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: var(--accent-palette-200);
    }
  }

  textarea {
    background: var(--accent-palette-100);
  }
}

.colored-theme {

  /////////////////////////////////////
  /////////// COLORED THEME /////////////
  /////////////////////////////////////

  html, body {
    background: mat.m2-get-color-from-palette(theme.$accent-palette, 500);
  }

  strong {
    color: map.get(theme.$primary-palette, 500);
  }

  /* Change the white to any color */
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-text-fill-color: mat.m2-get-contrast-color-from-palette(theme.$accent-palette, 300);
    -webkit-box-shadow: 0 0 0 30px mat.m2-get-color-from-palette(theme.$accent-palette, 300) inset !important;
  }

  .mat-mdc-select-panel {
    background: white;
    color: black;
  }

  .mat-mdc-unelevated-button {
    border-radius: 8px;

    &.mat-primary {
      color: mat.m2-get-color-from-palette(theme.$primary-palette, 500) !important;
    }

    &.mat-accent {
      border: 1px solid mat.m2-get-contrast-color-from-palette(theme.$accent-palette, 500) !important;
      color: mat.m2-get-contrast-color-from-palette(theme.$accent-palette, 500) !important;
    }
  }

  .mat-mdc-button.mat-mdc-button-base {
    color: mat.m2-get-color-from-palette(theme.$accent-palette, 500) !important;
  }

  .mdc-button[disabled] {
    opacity: 0.25;
    background-color: mat.m2-get-color-from-palette(theme.$primary-palette, 500) !important;
    color: mat.m2-get-color-from-palette(theme.$accent-palette, 500) !important;
  }

  .mat-form-field-appearance-outline {
    color: transparent;
    background: mat.m2-get-color-from-palette(theme.$accent-palette, 400);
  }

  .mat-mdc-form-field-outline-start,
  .mat-mdc-form-field-outline-gap,
  .mat-mdc-form-field-outline-end {
    color: mat.m2-get-color-from-palette(theme.$accent-palette, 400);
  }

  .mat-mdc-checkbox .mdc-checkbox__background {
    border-color: mat.m2-get-color-from-palette(theme.$primary-palette, 500) !important;
    width: 16px;
    height: 16px;
  }

  .mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background {
    background-color: mat.m2-get-color-from-palette(theme.$primary-palette, 500) !important;
  }

  .mdc-checkbox__checkmark-path {
    stroke: mat.m2-get-color-from-palette(theme.$accent-palette, 500) !important;
  }

  .amplify-button--primary {
    color: map.get(theme.$accent-palette, 500) !important;
  }

  .amplify-input {
    background-color: mat.m2-get-color-from-palette(theme.$accent-palette, 400) !important;
  }

  .background-cards {
    background: mat.m2-get-color-from-palette(theme.$accent-palette, 400);
  }

  .fill-transparent {
    fill: transparent;
  }

  .colored-stroke-accent-contrast {
    stroke: mat.m2-get-contrast-color-from-palette(theme.$accent-palette, 500);
  }

  .dropdown-toggle {
    background-color: mat.m2-get-color-from-palette(theme.$accent-palette, 400);
  }

  cr-loader {
    p {
      color: mat.m2-get-contrast-color-from-palette(theme.$primary-palette, 500);
    }
    svg {
      stroke: mat.m2-get-color-from-palette(theme.$primary-palette, 500) !important;
    }
  }

  .fill-accent-bulb {
    fill: mat.m2-get-color-from-palette(theme.$accent-palette, 500);
  }

  .dialog-cookies {
    .background {
      background: mat.m2-get-color-from-palette(theme.$accent-palette, 100);
    }
    .color {
      color: map.get(theme.$accent-palette, 500);
    }
  }

  .shadow {
    box-shadow: inset 0 -110px 60px -25px rgba(35,35,35, 0.8);
    position: absolute;
    pointer-events: none;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
  }

  .scrollbar {
    &::-webkit-scrollbar-thumb {
      background: var(--accent-palette-200);
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: var(--accent-palette-100);
    }
  }

  textarea {
    background: var(--accent-palette-400);
  }

}

.mat-mdc-form-field-icon-suffix {
  display: flex;
  align-items: center;
  height: 100%;
  border-left: solid 2px mat.m2-get-color-from-palette(theme.$accent-palette, 500) !important;
  padding-left: 12px !important;
  padding-right: 8px !important;

  .mat-icon {
    width: 1rem;
    height: 1rem;
    font-size: 1rem;
    padding-left: 4px !important;
    padding-right: 4px !important;
    color: mat.m2-get-contrast-color-from-palette(theme.$accent-palette, 500) ;
  }
}


.auto-rotate {
  animation: rotate 3s linear infinite;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.auto-pulse {
  transform: scale(1);
  animation: pulse 3s linear infinite;
}

@keyframes pulse {
  0% {
    transform: scale(0.8);
  }

  70% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(0.8);
  }
}


.stroke-mask-color {
  stroke: var(--mask-color, #FFC500);
}

.fill-mask-color {
  fill: var(--mask-color, #FFC500);
}

.text-primary {
  color: mat.m2-get-color-from-palette(theme.$primary-palette, 400);
}

.ripple {
  position: absolute;
  border-radius: 50%;
  transform: scale(0);
  background-color: rgba(0, 0, 0, 0.4);
  animation: ripple-effect 1s;
}

@keyframes ripple-effect {
  to {
    transform: scale(1.5);
    opacity: 0;
  }
}

.scrollbar {
  /* width */
  &::-webkit-scrollbar {
    width: 4px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: transparent;
    border: solid 3px transparent;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    border-radius: 12px;
    border: solid 3px transparent;
  }
}
