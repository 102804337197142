:root {
  --success-color: #00B050;
  --error-color: #CB1D00;
}

.bg-success {
  background-color: var(--success-color);
}

.bg-error {
  background-color: var(--error-color);
}
