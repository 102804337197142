// MATERIAL
@use 'sass:map';
@use 'node_modules/@angular/material/index' as mat;

@import "styles/mixins";

@mixin theme($theme) {
  .light-theme {
    .dropdown  {
      & + div > div {
        box-shadow: 0 0 8px var(--accent-palette-contrast-300);
        background: var(--accent-palette-100);
      }
      &:focus {
        @include halo-effect('light');
      }
    }
    .halo{
      @include halo-effect('light');
    }
  }

  .colored-theme {
    .dropdown  {
      & + div > div {
        box-shadow: 0 0 8px var(--accent-palette-100);
        background: var(--accent-palette-500);
      }
      &:focus {
        @include halo-effect('colored');
      }
    }
    .halo{
      @include halo-effect('colored');
    }
  }
}
