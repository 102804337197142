// MATERIAL
@use 'sass:map';
@use 'node_modules/@angular/material/index' as mat;

@mixin border($theme) {
  .colored-theme {
    .dim-container {
      border: solid 1px var(--accent-palette-500);
    }
  }
  .light-theme {
    .dim-container {
      border: solid 1px var(--primary-palette-contrast-200);
    }
  }
}

@mixin background($theme) {
  .colored-theme {

    .dim-container {
      background: var(--accent-palette-contrast-400);
    }
  }
}

@mixin color($theme) {
  .colored-theme {
    .dim-container {
      color: var(--accent-palette-400);
    }
  }
}

@mixin theme($theme) {
  $color-config: mat.m2-get-color-config($theme);

  @if $color-config != null {
    @include border($theme);
    @include background($theme);
    @include color($theme);
  }
}
