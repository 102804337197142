.cds-button-icon {
  padding: 8px;
  border-radius: 8px;

  &:hover {
    background: var(--primary-palette-500);

    cds-svg-icon {
      color: var(--primary-palette-contrast-500);
    }
  }

  cds-svg-icon {
    color: var(--accent-palette-200)
  }

}

.cds-button {
  border-radius: 8px;
  padding: 0 8px 0 8px;
  -webkit-appearance: button;
  background-image: none;
  background-color: transparent;
  border-width: 0;
  cursor: pointer;

  &:disabled {
    cursor: default;
    pointer-events: none;
  }
}

.cds-button-big {
  min-height: 56px;
  font-size: 1.5rem;
  font-weight: 700;
}

.cds-button-small {
  min-height: 40px;
  font-size: 1em;
  font-weight: 700;
}

.cds-link-button {
  font-size: 0.90rem;
  font-weight: 700;
}

.colored-theme {

  .cds-button:disabled {
    opacity: .25;
  }

  .cds-button-primary {
    color: var(--accent-palette-500);
    background-color: var(--primary-palette-500);
  }

  .cds-button-accent {
    color: var(--accent-palette-contrast-500);
    border: solid 1px var(--accent-palette-contrast-500);
  }

  .cds-link-button {
    text-decoration: underline;
    border: none;
    background: transparent;

    &.cds-button-primary {
      color: var(--primary-palette-500);
    }

    &.cds-button-accent {
      color: var(--accent-palette-contrast-500);
    }
  }

}

.light-theme {

  .cds-button:disabled {
    background-color: var(--accent-palette-500);
  }

  .cds-button-primary {
    background-color: var(--primary-palette-500);
    color: var(--primary-palette-contrast-500);
  }

  .cds-button-accent {
    color: var(--accent-palette-contrast-500);
    border: solid 1px var(--accent-palette-contrast-500);
  }

  .cds-link-button {
    text-decoration: underline;
    border: none;
    background: transparent;

    &:disabled {
      background: transparent;
      opacity: 0.25;
    }

    &.cds-button-primary {
      color: var(--primary-palette-500);
    }

    &.cds-button-accent {
      color: var(--accent-palette-contrast-500);
    }
  }
}


