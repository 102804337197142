// MATERIAL
@use 'sass:map';
@use 'node_modules/@angular/material/index' as mat;

@mixin theme($theme) {
  .light-theme app-garage-appointment-card {
    button[disabled] {
      background: var(--accent-palette-300) !important;
    }
    .themed-container {
      background: var(--accent-palette-200);
    }
  }

  .colored-theme app-garage-appointment-card {
    button[disabled] {
      background: var(--accent-palette-200) !important;
    }
    .themed-container {
      background: var(--accent-palette-300);
    }
  }
}
