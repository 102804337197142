// MATERIAL
@use 'sass:map';
@use 'node_modules/@angular/material/index' as mat;

@mixin color($theme) {

  // Get the color config from the theme.
  $color-config: mat.m2-get-color-config($theme);

  $primary-palette: map.get($color-config, 'primary');

  .light-theme {
    .tab-btn {
      &.tab-selected {
        color: mat.m2-get-color-from-palette($primary-palette, 500);
      }
    }

    .tab-button {
      .tab-btn {
        &.tab-selected {
          color: var(--primary-palette-contrast-500) !important;
        }
      }
    }
  }

  .colored-theme {
    .tab-btn {
      &.tab-selected {
        color: mat.m2-get-color-from-palette($primary-palette, 500);
      }
    }

    .tab-button {
      .tab-btn {
        &.tab-selected {
          color: var(--primary-palette-contrast-500) !important;
        }
      }
    }
  }

}

@mixin background($theme) {

  // Get the color config from the theme.
  $color-config: mat.m2-get-color-config($theme);

  $primary-palette: map.get($color-config, 'primary');

  .light-theme {
    .tab-tab {
      .tab-btn {
        background: white;
      }
    }
  }

  .light-theme {
    .tab-button {
      background: white;
      .tab-btn {
        &.tab-selected {
          background: var(--primary-palette-500);
        }
      }
    }
  }

  .colored-theme {
    .tab-button {
      .tab-btn {
        &.tab-selected {
          background: var(--primary-palette-500);
        }
      }
    }
  }
}

@mixin border($theme) {

  // Get the color config from the theme.
  $color-config: mat.m2-get-color-config($theme);

  $primary-palette: map.get($color-config, 'primary');

  .colored-theme, .light-theme {
    .tab-tab {
      .tab-btn {
        &.tab-selected {
          border-color: mat.m2-get-color-from-palette($primary-palette, 500);
          border-bottom: solid 2px;
          border-radius: 0 !important;
        }
      }
    }

  }
}

@mixin theme($theme) {
  $color-config: mat.m2-get-color-config($theme);

  @if $color-config != null {
    @include color($theme);
    @include border($theme);
    @include background($theme);
  }
}
