// MATERIAL
@use 'sass:map';
@use 'node_modules/@angular/material/index' as mat;

@mixin theme($theme) {
  $color-config: mat.m2-get-color-config($theme);

  @if $color-config != null {

    .colored-theme {
      .white-container {
        p {
          color: var(--accent-palette-500);
        }
      }
    }
  }
}
