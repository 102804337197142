// MATERIAL
@use 'sass:map';
@use 'node_modules/@angular/material/index' as mat;

@mixin background($theme) {

  // Get the color config from the theme.
  $color-config: mat.m2-get-color-config($theme);

  $primary-palette: map.get($color-config, 'primary');
  $accent-palette: map.get($color-config, 'accent');

  .light-theme, .colored-theme  {
    .next-button {
      background: mat.m2-get-color-from-palette($primary-palette, 500);
    }

    li:before {
      background: map.get($accent-palette, 500);
    }
  }
}

@mixin color($theme) {

  // Get the color config from the theme.
  $color-config: mat.m2-get-color-config($theme);

  // Get the primary color palette from the color-config.
  $accent-palette: map.get($color-config, 'accent');
  $primary-palette: map.get($color-config, 'primary');

  .light-theme {
    .next-button {
      color: mat.m2-get-contrast-color-from-palette($primary-palette, 500);
    }
  }
  .colored-theme {
    .next-button {
      color: mat.m2-get-color-from-palette($accent-palette, 500);
    }
  }
}

@mixin theme($theme) {
  $color-config: mat.m2-get-color-config($theme);

  @if $color-config != null {
    @include background($theme);
    @include color($theme);
  }
}
