// MATERIAL
@use 'sass:map';
@use 'node_modules/@angular/material/index' as mat;

@mixin theme($theme) {
  $color-config: mat.m2-get-color-config($theme);

  @if $color-config != null {

    .colored-theme {
      .maker-container {
        background: var(--primary-palette-A100);
        color: var(--accent-palette-500);
      }
    }

    .light-theme {
      .maker-container {
        background: var(--accent-palette-A100);
        color: var(--primary-palette-500);
      }
    }

  }
}
