@use "base/reset";
@use "components/places";
@use "components/input";
@use "components/containers";
@use "components/progress-bar";
@use "components/buttons";

.fill-primary-contrast {
  fill: var(--primary-palette-contrast-500);
}

.fill-primary {
  fill: var(--primary-palette-500);
}

.fill-accent-contrast {
  fill: var(--accent-palette-contrast-500);
}
