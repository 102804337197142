// MATERIAL
@use 'sass:map';
@use 'node_modules/@angular/material/index' as mat;

@mixin color($theme) {

  // Get the color config from the theme.
  $color-config: mat.m2-get-color-config($theme);

  $primary-palette: map.get($color-config, 'primary');

  .light-theme {
    .tab-button {
      &.tab-selected {
        color: mat.m2-get-contrast-color-from-palette($primary-palette, 500) !important;
      }
    }
  }

  .colored-theme {
    .tab-button {
      &.tab-selected {
        color: mat.m2-get-color-from-palette($primary-palette, 500) !important;
      }
    }
  }

}

@mixin border($theme) {

  // Get the color config from the theme.
  $color-config: mat.m2-get-color-config($theme);

  $primary-palette: map.get($color-config, 'primary');

  .light-theme {
    .tab-button {
      &.tab-selected {
        border: none;
      }
    }
  }

  .colored-theme {
    .tab-button {
      &.tab-selected {
        border-color: mat.m2-get-color-from-palette($primary-palette, 500);
        border-bottom: solid 2px;
        border-radius: 0 !important;
      }
    }
  }
}

@mixin background($theme) {

  // Get the color config from the theme.
  $color-config: mat.m2-get-color-config($theme);

  $primary-palette: map.get($color-config, 'primary');

  .light-theme {
    .tab-button {
      &.tab-selected {
        background: mat.m2-get-color-from-palette($primary-palette, 500);
      }
    }
  }

}


@mixin theme($theme) {
  $color-config: mat.m2-get-color-config($theme);

  @if $color-config != null {
    @include color($theme);
    @include border($theme);
    @include background($theme)
  }

  .colored-theme {

    .tab-container {
      margin-bottom: 1em;
    }

  }

  .light-theme {

    .tab-container {
      margin-bottom: 0.25em;
    }

  }

}
