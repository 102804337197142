// MATERIAL
@use 'sass:map';
@use 'node_modules/@angular/material/index' as mat;

@mixin theme($theme){
  $color-config: mat.m2-get-color-config($theme);

  @if $color-config != null {

    .light-theme {
      .icon-button {
        &:disabled {
          opacity: 0.7;
        }
      }
    }

    .colored-theme {
      .icon-button {
        &:disabled {
          opacity: 0.25;
        }
      }
    }
  }
}
