// MATERIAL
@use 'sass:map';
@use 'node_modules/@angular/material/index' as mat;

@mixin theme($theme) {
  // Get the color config from the theme.
  $color-config: mat.m2-get-color-config($theme);

  @if $color-config != null {

    // Get the primary color palette from the color-config.
    $accent-palette: map.get($color-config, 'accent');
    $primary-palette: map.get($color-config, 'primary');

    .light-theme, .colored-theme  {}

    .light-theme {
      .color {}
      .background {
        background: mat.m2-get-color-from-palette($accent-palette, 400);
      }
    }

    .colored-theme {
      .background {
        background: mat.m2-get-color-from-palette($accent-palette, 100);
      }
      .color {
        color: map.get($accent-palette, 500);
      }
    }
  }
}
