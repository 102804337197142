.cds-input {
  height: 48px;
  border-radius: 8px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  overflow: hidden;
  position: relative;
  font-size: 0.875rem;

  input {
    background: inherit;
    color: inherit;
    outline: none;
    border: none;
    flex: 1;
    padding: 16px 24px 16px 24px;
    height: inherit;
    width: 100%;
  }
}

.light-theme {

  .cds-input {
    background: var(--accent-palette-100);

    &:has(input:focus) {
      border: 1px solid var(--accent-palette-contrast-200);
      box-shadow: 0 0 8px var(--accent-palette-contrast-200);
    }

    input {
      &::placeholder {
        color: var(--accent-palette-contrast-200);
      }

      &:disabled {
        color: var(--accent-palette-contrast-200);
      }
    }
  }

  .cds-input-license {

    .cds-select {
      background: var(--accent-palette-100);
      border: 1px solid var(--accent-palette-contrast-200);
      box-shadow: 0 0 8px var(--accent-palette-contrast-200);
    }

    .cds-selected-flag {
      background-color: var(--accent-palette-100);
    }

  }

}

.colored-theme {

  .cds-input {
    background: var(--accent-palette-400);
    color: var(--accent-palette-contrast-400);

    &:has(input:focus) {
      border: 1px solid var(--accent-palette-100);
      box-shadow: 0 0 8px var(--accent-palette-100);
    }

    input {
      &::placeholder {
        color: var(--accent-palette-100);
      }

      &:disabled {
        color: var(--accent-palette-100);
      }
    }
  }

  .cds-input-license {

    .cds-select {
      background: var(--accent-palette-400);
      border: 1px solid var(--accent-palette-100);
      box-shadow: 0 0 8px var(--accent-palette-100);
    }

    .cds-selected-flag {
      background-color: var(--accent-palette-400);
    }

  }


}
