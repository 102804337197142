.pac-container {
  border-radius: 8px;
  border: none;
  box-shadow: none;
  background: var(--accent-palette-400);

  &:after {
    content: "Powered by Google";
    font-size: 0.625rem;
    background: none !important;
    padding: 4px 8px;
    height: auto;
  }
}

.pac-item, .pac-matched, .pac-item-query {
  color: var(--accent-palette-contrast-500);
}

.pac-item {
  height: 48px;
  padding: 24px 16px;
  border: none;
  margin-bottom: 2px;
  display: flex;
  align-items: center;
  border-bottom: solid 2px var(--accent-palette-500);

  span:last-of-type {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

}

.pac-item:hover {
  background: inherit;
  color: inherit;
}

.pac-icon {
  display: none;
}

