.small-p {
  font-weight: 400;
  font-size: 14px;
  line-height: 19.6px;
}

.regular-p {
  font-size: 18px;
  line-height: 23.4px;
}

@media(max-width: 375px) {
  p.regular-p {
    font-size: calc(18px * 0.85);
    line-height: calc(23.4px * 0.85);
  }
  p.small-p {
    font-size: calc(14px * 0.85);
    line-height: calc(19.6px * 0.85);
  }
}

