// MATERIAL
@use 'sass:map';
@use 'node_modules/@angular/material/index' as mat;

@mixin background($theme) {

  // Get the color config from the theme.
  $color-config: mat.m2-get-color-config($theme);

  // Get the primary color palette from the color-config.
  $accent-palette: map.get($color-config, 'accent');
  $primary-palette: map.get($color-config, 'primary');

  @media (orientation: portrait) {
    @media (min-height: 840px) {

      .light-theme {
        .border-container {
          background-color: #EDEFF5;
        }
      }
      .colored-theme {
        .border-container {
          background-color: mat.m2-get-color-from-palette($accent-palette, 700);
        }
      }
    }
  }

  @media (orientation: landscape) {
    @media (min-width: 640px) {

      .light-theme {
        .border-container {
          background-color: #EDEFF5;
        }
      }
      .colored-theme {
        .border-container {
          background-color: mat.m2-get-color-from-palette($accent-palette, 700);
        }
      }
    }
  }

  .light-theme {
    .action-button {
      background: mat.m2-get-color-from-palette($primary-palette, 500);
    }
  }
  .colored-theme {
    .action-button {
      background: white;
    }
  }
}

@mixin color($theme) {
  // Get the color config from the theme.
  $color-config: mat.m2-get-color-config($theme);

  // Get the primary color palette from the color-config.
  $accent-palette: map.get($color-config, 'accent');
  $primary-palette: map.get($color-config, 'primary');

  .position-container {
    border: solid 1px mat.m2-get-contrast-color-from-palette($accent-palette, 500);
  }

  .picture-button {
    border: solid 5px mat.m2-get-contrast-color-from-palette($accent-palette, 500);
  }

  .light-theme {
    .stroke-torch {
      stroke: mat.m2-get-contrast-color-from-palette($primary-palette, 500);
    }
    .action-button {
      color: mat.m2-get-contrast-color-from-palette($primary-palette, 500);
    }
  }
  .colored-theme {
    .action-button {
      color: mat.m2-get-color-from-palette($accent-palette, 500);
    }
  }
}

@mixin theme($theme) {
  $color-config: mat.m2-get-color-config($theme);

  @if $color-config != null {
    @include background($theme);
    @include color($theme);
  }
}
