// MATERIAL
@use 'sass:map';
@use 'node_modules/@angular/material/index' as mat;

@import "styles/mixins";

@mixin theme($theme) {
  .light-theme {
    textarea:focus {
      @include halo-effect('light');
    }
  }

  .colored-theme {
    textarea:focus {
      @include halo-effect('colored');
    }
  }
}
