.light-theme {
  .under {
    background-color: var(--accent-palette-contrast-200);
  }
  .container.vertical {
    .progress-bar {
      background-color: var(--accent-palette-contrast-200);
    }
  }
}

.colored-theme {
  .under {
    background-color: var(--accent-palette-contrast-500);
  }
  .container.vertical {
    .progress-bar {
      background-color: var(--accent-palette-contrast-500);
    }
  }
}
