@use 'sass:list';

/// Outputs the shorthand `font` CSS property, based on a set of typography values. Falls back to
/// the individual properties if a value that isn't allowed in the shorthand is passed in.
/// @param {String} $font-size The font-size value.
/// @param {String | Number} $font-weight The font-weight value.
/// @param {String | Number} $line-height The line-height value.
/// @param {String} $font-family The font-family value.
/// @returns {String} The `font` shorthand value combining the given parts.
@mixin font-shorthand($font-size, $font-weight, $line-height, $font-family) {
  // If any of the values are set to `inherit`, we can't use the shorthand
  // so we fall back to passing in the individual properties.
  @if ($font-size == inherit or
       $font-weight == inherit or
       $line-height == inherit or
       $font-family == inherit or
       $font-size == null or
       $font-weight == null or
       $line-height == null or
       $font-family == null) {

    font-size: $font-size;
    font-weight: $font-weight;
    line-height: $line-height;
    font-family: $font-family;
  }
  @else {
    // Otherwise use the shorthand `font`, because it's the least amount of bytes.
    font: $font-weight list.slash($font-size, $line-height) $font-family;
  }
}
