:root {
  --primary-palette-50: #fef7e0;
  --primary-palette-100: #fcecb3;
  --primary-palette-200: #fbdf80;
  --primary-palette-300: #f9d24d;
  --primary-palette-400: #f7c826;
  --primary-palette-500: #f6be00;
  --primary-palette-600: #f5b800;
  --primary-palette-700: #f3af00;
  --primary-palette-800: #f2a700;
  --primary-palette-900: #ef9900;
  --primary-palette-A100: #ffffff;
  --primary-palette-A200: #fff4e3;
  --primary-palette-A400: #ffdfb0;
  --primary-palette-A700: #ffd596;
  --primary-palette-contrast-50: #333333;
  --primary-palette-contrast-100: #333333;
  --primary-palette-contrast-200: #333333;
  --primary-palette-contrast-300: #333333;
  --primary-palette-contrast-400: #333333;
  --primary-palette-contrast-500: #333333;
  --primary-palette-contrast-600: #ffffff;
  --primary-palette-contrast-700: #ffffff;
  --primary-palette-contrast-800: #ffffff;
  --primary-palette-contrast-900: #ffffff;
  --primary-palette-contrast-A100: #333333;
  --primary-palette-contrast-A200: #333333;
  --primary-palette-contrast-A400: #ffffff;
  --primary-palette-contrast-A700: #333333;

  --accent-palette-50: #e1e4e8;
  --accent-palette-100: #b4bcc6;
  --accent-palette-200: #828fa1;
  --accent-palette-300: #1d3555;
  --accent-palette-400: #13294b;
  --accent-palette-500: #041e42;
  --accent-palette-600: #031a3c;
  --accent-palette-700: #031633;
  --accent-palette-800: #02122b;
  --accent-palette-900: #010a1d;
  --accent-palette-A100: #5b78ff;
  --accent-palette-A200: #284eff;
  --accent-palette-A400: #002bf4;
  --accent-palette-A700: #0027da;

  --accent-palette-contrast-50: #333333;
  --accent-palette-contrast-100: #333333;
  --accent-palette-contrast-200: #b8b8b8;
  --accent-palette-contrast-300: #efefef;
  --accent-palette-contrast-400: #ebebeb;
  --accent-palette-contrast-500: #e8e8e8;
  --accent-palette-contrast-600: #e5e5e5;
  --accent-palette-contrast-700: #e2e2e2;
  --accent-palette-contrast-800: #dedede;
  --accent-palette-contrast-900: #d8d8d8;
  --accent-palette-contrast-A100: #ffffff;
  --accent-palette-contrast-A200: #ffffff;
  --accent-palette-contrast-A400: #ffffff;
  --accent-palette-contrast-A700: #ffffff;
}

$primary-palette: (
  50 : var(--primary-palette-50),
  100 : var(--primary-palette-100),
  200 : var(--primary-palette-200),
  300 : var(--primary-palette-300),
  400 : var(--primary-palette-400),
  500 : var(--primary-palette-500),
  600 : var(--primary-palette-600),
  700 : var(--primary-palette-700),
  800 : var(--primary-palette-800),
  900 : var(--primary-palette-900),
  A100 : var(--primary-palette-A100),
  A200 : var(--primary-palette-A200),
  A400 : var(--primary-palette-A400),
  A700 : var(--primary-palette-A700),
  contrast: (
    50 : var(--primary-palette-contrast-50),
    100 : var(--primary-palette-contrast-100),
    200 : var(--primary-palette-contrast-200),
    300 : var(--primary-palette-contrast-300),
    400 : var(--primary-palette-contrast-400),
    500 : var(--primary-palette-contrast-500),
    600 : var(--primary-palette-contrast-600),
    700 : var(--primary-palette-contrast-700),
    800 : var(--primary-palette-contrast-800),
    900 : var(--primary-palette-contrast-900),
    A100 : var(--primary-palette-contrast-A100),
    A200 : var(--primary-palette-contrast-A200),
    A400 : var(--primary-palette-contrast-A400),
    A700 : var(--primary-palette-contrast-A700),
  )
);

$accent-palette: (
  50 : var(--accent-palette-50),
  100 : var(--accent-palette-100),
  200 : var(--accent-palette-200),
  300 : var(--accent-palette-300),
  400 : var(--accent-palette-400),
  500 : var(--accent-palette-500),
  600 : var(--accent-palette-600),
  700 : var(--accent-palette-700),
  800 : var(--accent-palette-800),
  900 : var(--accent-palette-900),
  A100 : var(--accent-palette-A100),
  A200 : var(--accent-palette-A200),
  A400 : var(--accent-palette-A400),
  A700 : var(--accent-palette-A700),
  contrast: (
    50 : var(--accent-palette-contrast-50),
    100 : var(--accent-palette-contrast-100),
    200 : var(--accent-palette-contrast-200),
    300 : var(--accent-palette-contrast-300),
    400 : var(--accent-palette-contrast-400),
    500 : var(--accent-palette-contrast-500),
    600 : var(--accent-palette-contrast-600),
    700 : var(--accent-palette-contrast-700),
    800 : var(--accent-palette-contrast-800),
    900 : var(--accent-palette-contrast-900),
    A100 : var(--accent-palette-contrast-A100),
    A200 : var(--accent-palette-contrast-A200),
    A400 : var(--accent-palette-contrast-A400),
    A700 : var(--accent-palette-contrast-A700),
  )
);

