// MATERIAL
@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme) {

  // Get the color config from the theme.
  $color-config: mat.m2-get-color-config($theme);

  // Get the primary color palette from the color-config.
  $accent-palette: map.get($color-config, 'accent');

  .light-theme {
    footer {
      a {
        color: mat.m2-get-color-from-palette($accent-palette, A100) !important;
      }
    }
  }
  .colored-theme {
    footer {
      a {
        color: mat.m2-get-contrast-color-from-palette($accent-palette, 700) !important;
      }
    }
  }

}

@mixin background($theme) {

  // Get the color config from the theme.
  $color-config: mat.m2-get-color-config($theme);

  // Get the primary color palette from the color-config.
  $accent-palette: map.get($color-config, 'accent');

  .light-theme {
    footer {
      background-color: mat.m2-get-contrast-color-from-palette($accent-palette, A100) !important;
    }
  }
  .colored-theme {
    footer {
      background-color: mat.m2-get-color-from-palette($accent-palette, 700) !important;
    }
  }

}

@mixin theme($theme) {
  $color-config: mat.m2-get-color-config($theme);

  @if $color-config != null {
    @include background($theme);
    @include color($theme);
  }
}
