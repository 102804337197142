// MATERIAL
@use 'sass:map';
@use 'node_modules/@angular/material/index' as mat;

@mixin theme($theme) {
  $color-config: mat.m2-get-color-config($theme);

  @if $color-config != null {

    // Get the primary color palette from the color-config.
    $primary-palette: map.get($color-config, 'primary');

    p {
      color:  mat.m2-get-contrast-color-from-palette($primary-palette, 400);
    }

    #home-car {
      border-color: mat.m2-get-color-from-palette($primary-palette, 500);
      color: mat.m2-get-color-from-palette($primary-palette, 500);
    }
  }
}
