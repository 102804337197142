.block-container {
  border-radius: 8px;
  overflow: hidden;
}

.light-theme {
  .block-container {
    background: var(--accent-palette-500);
  }
}

.colored-theme {

  .block-container {
    background: var(--accent-palette-400);

    p, h3 {
      color: var(--accent-palette-contrast-400)
    }

  }

}
