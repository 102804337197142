@mixin halo-effect($theme) {
  $color: var(--accent-palette-100);

  @if $theme == 'light' {
    $color: var(--accent-palette-contrast-300);
  } @else if $theme == 'colored' {
    $color: var(--accent-palette-100);
  }

  outline: 1px solid $color;
  box-shadow: 0 0 8px $color;
}
