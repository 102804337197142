@use '../core/style/sass-utils';
@use '../core/theming/theming';
@use '../core/theming/inspection';
@use '../core/theming/validation';
@use '../core/tokens/token-utils';
@use '../core/typography/typography';
@use '../core/tokens/m2/mat/switch' as tokens-mat-switch;
@use '../core/tokens/m2/mdc/switch' as tokens-mdc-switch;

/// Outputs base theme styles (styles not dependent on the color, typography, or density settings)
/// for the mat-slide-toggle.
/// @param {Map} $theme The theme to generate base styles for.
@mixin base($theme) {
  @if inspection.get-theme-version($theme) == 1 {
    @include _theme-from-tokens(inspection.get-theme-tokens($theme, base));
  } @else {
    @include sass-utils.current-selector-or-root() {
      @include token-utils.create-token-values(
        tokens-mdc-switch.$prefix,
        tokens-mdc-switch.get-unthemable-tokens()
      );

      .mat-mdc-slide-toggle {
        @include token-utils.create-token-values(
          tokens-mat-switch.$prefix,
          tokens-mat-switch.get-unthemable-tokens()
        );
      }
    }
  }
}

/// Outputs color theme styles for the mat-slide-toggle.
/// @param {Map} $theme The theme to generate color styles for.
/// @param {ArgList} Additional optional arguments (only supported for M3 themes):
///   $color-variant: The color variant to use for the slide-toggle: primary, secondary, tertiary,
///      or error (If not specified, default primary color will be used).
@mixin color($theme, $options...) {
  @if inspection.get-theme-version($theme) == 1 {
    @include _theme-from-tokens(inspection.get-theme-tokens($theme, color), $options...);
  } @else {
    $mdc-tokens: tokens-mdc-switch.get-color-tokens($theme);

    // Add values for MDC slide toggles tokens
    @include sass-utils.current-selector-or-root() {
      @include token-utils.create-token-values(
        tokens-mdc-switch.$prefix,
        tokens-mdc-switch.get-color-tokens($theme)
      );

      & {
        // TODO(wagnermaciel): Use our token system to define this css variable.
        --mdc-switch-disabled-label-text-color: #{inspection.get-theme-color(
            $theme,
            foreground,
            disabled-text
          )};
      }

      .mat-mdc-slide-toggle {
        @include token-utils.create-token-values(
          tokens-mat-switch.$prefix,
          tokens-mat-switch.get-color-tokens($theme)
        );

        // Change the color palette related tokens to accent or warn if applicable
        &.mat-accent {
          @include token-utils.create-token-values(
            tokens-mdc-switch.$prefix,
            tokens-mdc-switch.private-get-color-palette-color-tokens($theme, accent)
          );
        }

        &.mat-warn {
          @include token-utils.create-token-values(
            tokens-mdc-switch.$prefix,
            tokens-mdc-switch.private-get-color-palette-color-tokens($theme, warn)
          );
        }
      }
    }
  }
}

/// Outputs typography theme styles for the mat-slide-toggle.
/// @param {Map} $theme The theme to generate typography styles for.
@mixin typography($theme) {
  @if inspection.get-theme-version($theme) == 1 {
    @include _theme-from-tokens(inspection.get-theme-tokens($theme, typography));
  } @else {
    @include sass-utils.current-selector-or-root() {
      @include token-utils.create-token-values(
        tokens-mdc-switch.$prefix,
        tokens-mdc-switch.get-typography-tokens($theme)
      );

      .mat-mdc-slide-toggle {
        @include token-utils.create-token-values(
          tokens-mat-switch.$prefix,
          tokens-mat-switch.get-typography-tokens($theme)
        );
      }
    }
  }
}

/// Outputs density theme styles for the mat-slide-toggle.
/// @param {Map} $theme The theme to generate density styles for.
@mixin density($theme) {
  @if inspection.get-theme-version($theme) == 1 {
    @include _theme-from-tokens(inspection.get-theme-tokens($theme, density));
  } @else {
    @include sass-utils.current-selector-or-root() {
      @include token-utils.create-token-values(
        tokens-mdc-switch.$prefix,
        tokens-mdc-switch.get-density-tokens($theme)
      );

      .mat-mdc-slide-toggle {
        @include token-utils.create-token-values(
          tokens-mat-switch.$prefix,
          tokens-mat-switch.get-density-tokens($theme)
        );
      }
    }
  }
}

/// Defines the tokens that will be available in the `overrides` mixin and for docs extraction.
@function _define-overrides() {
  @return (
    (
      namespace: tokens-mat-switch.$prefix,
      tokens: tokens-mat-switch.get-token-slots(),
    ),
    (
      namespace: tokens-mdc-switch.$prefix,
      tokens: tokens-mdc-switch.get-token-slots(),
    ),
  );
}

/// Outputs the CSS variable values for the given tokens.
/// @param {Map} $tokens The token values to emit.
@mixin overrides($tokens: ()) {
  @include token-utils.batch-create-token-values($tokens, _define-overrides()...);
}

/// Outputs all (base, color, typography, and density) theme styles for the mat-icon.
/// @param {Map} $theme The theme to generate styles for.
/// @param {ArgList} Additional optional arguments (only supported for M3 themes):
///   $color-variant: The color variant to use for the slide-toggle: primary, secondary, tertiary,
///      or error (If not specified, default primary color will be used).
@mixin theme($theme, $options...) {
  @include theming.private-check-duplicate-theme-styles($theme, 'mat-slide-toggle') {
    @if inspection.get-theme-version($theme) == 1 {
      @include _theme-from-tokens(inspection.get-theme-tokens($theme), $options...);
    } @else {
      @include base($theme);
      @if inspection.theme-has($theme, color) {
        @include color($theme);
      }
      @if inspection.theme-has($theme, density) {
        @include density($theme);
      }
      @if inspection.theme-has($theme, typography) {
        @include typography($theme);
      }
    }
  }
}

@mixin _theme-from-tokens($tokens, $options...) {
  @include validation.selector-defined(
    'Calls to Angular Material theme mixins with an M3 theme must be wrapped in a selector'
  );
  $mdc-switch-tokens: token-utils.get-tokens-for($tokens, tokens-mdc-switch.$prefix, $options...);
  // Don't pass $options here, since the mat-switch doesn't support color options,
  // only the mdc-switch does.
  $mat-switch-tokens: token-utils.get-tokens-for($tokens, tokens-mat-switch.$prefix);

  @include token-utils.create-token-values(tokens-mdc-switch.$prefix, $mdc-switch-tokens);
  @include token-utils.create-token-values(tokens-mat-switch.$prefix, $mat-switch-tokens);
}
